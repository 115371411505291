import dayjs, { Dayjs } from 'dayjs'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import ChartHorizontalLegend from './Components/ChartHorizontalLegend.vue'
import ChartVerticalLegend from './Components/ChartVerticalLegends.vue'
import { deepCopy } from '@/utils/general'
import ChartExpansibleLegend from './Components/ChartExpansibleLegends.vue'
import ChartDetailLegends from './Components/ChartDetailLegends.vue'
import _ from 'lodash'
@Component({
  computed: {
    ...mapGetters('app', ['isMobile']),
  },
  components: {
    ChartHorizontalLegend,
    ChartVerticalLegend,
    ChartExpansibleLegend,
    ChartDetailLegends,
  },
})
export class BaseChart extends Vue {
  @Prop({ default: undefined }) records: Record<string, any>
  @Prop({ default: true, type: Boolean }) filterZeroLegends
  @Prop({ default: false, type: Boolean }) debugging
  @Prop({ default: false, type: Boolean }) isCurrency // Define si formatea cantidades como moneda
  @Prop({ default: false, type: Boolean }) inverseColors
  @Prop({ default: true, type: Boolean }) useLoading
  isMobile!: boolean;
  chartOptions: Record<string, any> = {}
  series = []
  display = false
  processingData = false

  Debug (...args) {
    const { debugging } = this
    if (debugging) {
      console.log(...args)
    }
  }

  fillWithNull (records: Record<string, any>[], targetLength: number) {
    for (let i = records.length; i < targetLength; i++) {
      records.push(null)
    }

    return records
  }

  generateAccumulated (arr: number[]) {
    const accumulated: number[] = []
    for (let i = 0; i < arr.length; i++) {
      if (i === 0) accumulated.push(arr[0])
      else {
        accumulated.push(arr[i] + accumulated[i - 1])
      }
    }
    return accumulated
  }

  getCategories (records: Record<string, any>, path: string[], categoryName = 'categoría', debugging = false): Record<string, any> {
    const categories = []
    records.forEach(record => {
      let category = this.getObjectAttribute(record, path)
      if (!category?.id) {
        if (debugging) console.log(`SIN ${categoryName}`, record)
        category = {
          id: undefined,
          description: `Sin ${categoryName}`,
          name: `Sin ${categoryName}`,
          color: '#737373',
        }
      }
      const containsCategory = categories.map(category => category?.id).includes(category?.id)
      if (!containsCategory) {
        categories.push(category)
      }
    })
    return categories
  }

  getObjectAttribute (object, path: string[]): any {
    const pathCopy = deepCopy(path)
    if (pathCopy.length > 0) {
      const attribute = pathCopy.shift()
      const returnValue = object?.[attribute]
      if (returnValue !== undefined) {
        return this.getObjectAttribute(returnValue, pathCopy)
      } else {
        return undefined
      }
    }
    return object
  }

  generateDayRange (start: Dayjs, end: Dayjs, monthly = false) {
    const range: Dayjs[] = [start]
    let newDate = dayjs(start)

    while (newDate.isBefore(end)) {
      newDate = newDate.add(1, monthly ? 'month' : 'day')
      range.push(newDate)
    }
    if (monthly) {
      range.pop()
    }
    return range
  }

  get parsedRecords () {
    const { records } = this
    return _.cloneDeep(
      records?.records?.aggregates?.nodes ||
      records?.records?.aggregate?.nodes ||
      records?.records?.nodes ||
      records?.records ||
      records
    )
  }

  get loading () {
    const { parsedRecords } = this
    return !parsedRecords
  }

  get width () {
    const { $vuetify, isMobile } = this

    return isMobile ? $vuetify.breakpoint.width - 50 : $vuetify.breakpoint.width - 725
  }

  get height () {
    const { isMobile } = this
    return isMobile ? 300 : 600
  }

  get small () {
    const { $vuetify, isMobile } = this

    return isMobile ? $vuetify.breakpoint.width - 25 : $vuetify.breakpoint.width - 10
  }

  get months () {
    return {
      '01': {
        full: 'Enero',
        short: 'ene',
      },
      '02': {
        full: 'Febrero',
        short: 'feb',
      },
      '03': {
        full: 'Marzo',
        short: 'mar',
      },
      '04': {
        full: 'Abril',
        short: 'abr',
      },

      '05': {
        full: 'Mayo',
        short: 'may',
      },
      '06': {
        full: 'Junio',
        short: 'jun',
      },
      '07': {
        full: 'Julio',
        short: 'jul',
      },
      '08': {
        full: 'Agosto',
        short: 'ago',
      },
      '09': {
        full: 'Septiembre',
        short: 'sep',
      },
      10: {
        full: 'Octubre',
        short: 'oct',
      },
      11: {
        full: 'Noviembre',
        short: 'nov',
      },
      12: {
        full: 'Diciembre',
        short: 'dic',
      },
    }
  }

  getBarColor (percentage) {
    const colors = this.inverseColors ? [
      {
        threshold: 25,
        color: '#FF9E2C', // orange
      },
      {
        threshold: 50,
        color: '#FAD901', // yellow
      },
      {
        threshold: 75,
        color: '#13CE66', // green
      },
      {
        threshold: 95,
        color: '#6185DB', // blue
      },
      {
        threshold: 101,
        color: '#F03738', // red
      },
    ] : [
      {
        threshold: 25,
        color: '#F03738', // red
      },
      {
        threshold: 50,
        color: '#FF9E2C', // orange
      },
      {
        threshold: 75,
        color: '#FAD901', // yellow
      },
      {
        threshold: 99,
        color: '#13CE66', // green
      },
      {
        threshold: 101,
        color: '#6185DB', // blue
      },
    ]

    let returnColor = colors[0].color
    if (percentage >= 100) {
      returnColor = colors[colors.length - 1].color
    }

    if (!percentage) return returnColor

    colors
      .sort((prev, next) => {
        return next.threshold - prev.threshold
      })
      .forEach(color => {
        if (percentage < color.threshold) {
          returnColor = color.color
        }
      })

    return returnColor
  }
}
