<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card color="f5f5f5">
      <v-card-title class="text-h5">
        🛠️ Nuevos cambios
      </v-card-title>

      <v-card-text>
        <pre v-html="formattedContent" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="closeDialog">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator'
  import axios from 'axios'

@Component
  export default class ChangeLogDialog extends Vue {
  extractedVersion = ''
  dialog = false;
  rawContent = ''; // Contenido sin procesar del archivo .txt

  // Formatea el contenido para mostrarlo
  get formattedContent () {
    return this.formatText(this.rawContent)
  }

  // Método para cargar el archivo de texto
  async fetchTextFile () {
    try {
      const response = await axios.get('/changelogUser.txt') // Asegúrate de que el archivo esté en /public
      this.rawContent = response.data
      this.extractVersion()
    } catch (error) {
      console.error('Error al cargar el archivo .txt:', error)
    }
  }

  extractVersion () {
    const match = this.rawContent.match(/Versión\s([\d.]+)\s\(/) // Busca el patrón "Versión X.Y.Z ("
    if (match && match[1]) {
      this.extractedVersion = match[1]
      this.checkIfUserHasSeenChangelog() // Verifica si el usuario ya vio esta versión
    } else {
      console.warn('No se pudo extraer la versión del changelog.')
    }
  }

  // Convierte el texto en HTML con formato básico
  formatText (text) {
    return text
      // Títulos de diferentes niveles
      .replace(/^# (.*?)$/gm, '<h1>$1</h1>') // Encabezado nivel 1
      .replace(/^## (.*?)$/gm, '<h2>$1</h2>') // Encabezado nivel 2
      .replace(/^### (.*?)$/gm, '<h3>$1</h3>') // Encabezado nivel 3
      .replace(/^#### (.*?)$/gm, '<h4>$1</h4>') // Encabezado nivel 4

      // Negritas simples **texto**
      .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')

      // Viñetas con negritas (detecta correctamente guiones con texto en negrita)
      .replace(/^- (.*?)\*\*(.*?)\*\*(.*?)/gm, '<li>$1<b>$2</b>$3</li>')

      // Viñetas normales (sin negritas, pero detecta emojis si los hay)
      .replace(/^- (.*?)/gm, '<li>$1</li>')

      // Agrupar todas las <li> en listas <ul>
      .replace(/(<li>.*?<\/li>)/gs, '<ul>$1</ul>')

      // Saltos de línea no contenidos en listas o párrafos
      .replace(/\n/g, '<br>')
  }

  // Verifica si el usuario ya vio el changelog
  checkIfUserHasSeenChangelog () {
    const seenVersion = localStorage.getItem('changelog_version')

    if (seenVersion !== this.extractedVersion && this.extractedVersion.length) {
      this.dialog = true
    }
  }

  // Cierra el diálogo y guarda la versión en localStorage
  closeDialog () {
    localStorage.setItem('changelog_version', this.extractedVersion)
    this.dialog = false
  }

  // Carga el contenido al montar el componente
  async mounted () {
    this.checkIfUserHasSeenChangelog()
    await this.fetchTextFile()
  }
  }
</script>

<style scoped>
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

h1, h2, h3, h4 {
  margin-bottom: 10px;
}

li {
  margin-bottom: 5px;
}
</style>
