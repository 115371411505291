
  import { Debounce } from '@/utils/decorators'
  import { fixPrice } from '@/utils/general'
  import dayjs from 'dayjs'
  import Vue from 'vue'
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'

@Component({})
  export default class InspectionsReport extends Vue {
  @Prop() reports
  @Prop({ default: undefined, type: Number }) idExecutive
  executives = []
  reportsAttribute = []
  fixPrice = fixPrice
  dayjs = dayjs
  currentDate = dayjs()

  filterByExecutive (executive, id?) {
    if (id) {
      const filterById = this.reportsAttribute.filter(report => report.executive === id)
      executive = filterById?.[0]?.executiveName
    }
    return this.reportsAttribute.filter(report =>
      report.executiveName === executive
    )
  }

  getData () {
    if (!this.reports) return
    let reports = this.reports.getInspectionFinancialReport
    if (!reports) return
    reports = reports.sort((prev, next) =>
      dayjs(prev.scheduledDate).diff(dayjs(next.scheduledDate))
    )
    this.reportsAttribute = reports

    const executives = []
    reports.forEach(report => {
      if (!executives.includes(report.executiveName)) {
        executives.push(report.executiveName !== '' ? report.executiveName : 'SIN EJECUTIVO')
      }
    })
    this.executives = executives
  }

  get totalProyectedUtility () {
    let count = 0
    this.reportsAttribute.forEach(report => count += report.projectedUtility)
    return count
  }

  get totalInvestment () {
    let count = 0
    if (!this.idExecutive) {
      this.reportsAttribute.forEach(report => {
        count += Number(report.appraised)
      })
    } else {
      const filterById = this.filterByExecutive(undefined, this.idExecutive)
      filterById.forEach(report => {
        count += Number(report.appraised)
      })
    }
    return count
  }

  @Watch('reports', { immediate: true, deep: true })
  @Debounce(500)
  onReportsChange () {
    this.getData()
  }
  }

