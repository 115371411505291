
  import { fixAmountFormatted, fixPrice } from '@/utils/general'
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Legend } from './Legend'
  import { DetailElement } from './DetailElement'

@Component({})
  export default class ChartDetailLegend extends Vue {
  @Prop() legends: Legend[]
  @Prop() elements: DetailElement[]
  @Prop({ default: false, type: Boolean }) isCurrency
  @Prop({ default: 'total', type: String }) totalSufix
  @Prop({ default: false, type: Boolean }) filterZeroLegends
  @Prop({ default: false, type: Boolean }) sort

  formatValue (value: number): string {
    if (this.isCurrency) {
      return fixPrice(value)
    }
    return fixAmountFormatted(value)
  }

  get sortedLegends () {
    const { legends } = this
    if (this.sort) {
      return legends
        .filter(legend => this.filterZeroLegends ? legend.value !== 0 : true)
        .sort((prev, next) => next.value - prev.value)
    }
    return legends
      .filter(legend => this.filterZeroLegends ? legend.value !== 0 : true)
  }

  get total () {
    let sum = 0
    this.legends.forEach(legend => sum += legend.value)
    return sum
  }
  }
