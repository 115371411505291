export function fixColor (color: string): string {
  if (!color) return '#737373'
  const colorRegex = /#([a-fA-F0-9]{3}|[a-fA-F0-9]{6}|[a-fA-F0-9]{8})/
  const colorStrings = {
    black: '#000',
    white: '#fff',
    green: '#13CE66',
    purple: '#7D67BC',
    blue: '#6185DB',
    red: '#F03738',
  }
  if (!colorRegex.test(color) && colorStrings[color]) return colorStrings[color]
  return color.trim()
}

export function lowOpacityColor (color: string) {
  return `${fixColor(color)}4C`
}
